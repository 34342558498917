<template>
	<v-dialog
		v-model="qrDialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-card tile>
		<v-app-bar v-if="isIOS" app color="white" flat dense></v-app-bar>
			<v-app-bar color="#6200ea" class="mt-12" dark dense tile flat>
				<v-btn icon dark @click="closed">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>{{
					this.$t("global.components.qrReader.txtTitle")
				}}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-app-bar>

			<div v-if="select && !qrActive">
				<h4 class="mt-6 ml-4 mr-4 box">
					{{ $t("global.components.qrReader.txtOptions") }}:
				</h4>
				<div class="mt-6 ml-4 mr-4 box">
					<div
						class="item manual elevation-1"
						v-ripple
						@click.prevent="dialogCheck = true"
					>
						<div class="center">
							<v-icon>mdi-account-check-outline</v-icon>
							<span class="ml-2">Manual</span>
						</div>
					</div>
					<div
						class="item qr elevation-1"
						v-ripple
						@click="qrActive = true"
					>
						<div class="center">
							<v-icon class="icn">mdi-qrcode-scan</v-icon>
							<span class="ml-2">QR</span>
						</div>
					</div>
					<div class="clr"></div>
				</div>
				<v-alert prominent text type="info" class="mt-6 ml-4 mr-4">
					{{ $t("global.components.qrReader.txtInfoSelect") }}
				</v-alert>
			</div>

			<div v-if="qrActive || !select">
				<div v-if="qrDialog" class="mb-5">
					<qrcode-stream @decode="onDecode" @init="onInit">
						<v-row
							class="fill-height"
							align-content="center"
							justify="center"
							v-if="loading"
						>
							<v-col cols="6">
								<v-progress-linear
									color="deep-purple accent-4"
									indeterminate
									rounded
									height="6"
								></v-progress-linear>
							</v-col>
						</v-row>
					</qrcode-stream>
				</div>
				<v-alert
					prominent
					text
					type="info"
					class="mt-5 mb-5"
					v-if="qrDialog"
				>
					{{ $t("global.components.qrReader.txtInfo") }}
				</v-alert>

				<div class="pa-3">
					<v-btn
						color="deep-purple lighten-2 mb-3 mt-5"
						block
						dark
						tile
						text
						@click="closed"
					>
						{{ $t("global.dialog.btnCancel") }}
					</v-btn>
				</div>
			</div>
		</v-card>

		<v-dialog v-model="dialogCheck" persistent max-width="290">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t("global.components.qrReader.txtTitleCheckinM") }}
				</v-card-title>
				<v-card-text>{{
					$t("global.components.qrReader.txtDescM")
				}}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="darken-1" text @click="dialogCheck = false">
						{{ $t("global.dialog.btnCancel") }}
					</v-btn>
					<v-btn
						color="green darken-1"
						text
						@click.prevent="acepptCheck()"
					>
						Checkin
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapMutations, mapActions } from "vuex";
import { mapGetters } from "vuex"

export default {
	name: "QRReader",
	components: { QrcodeStream },
	props: {
		onlyEvent: {
			type: Boolean,
			default: function() {
				return false;
			},
		},
		select: {
			type: Boolean,
			default: function() {
				return false;
			},
		},
	},
	data: () => ({
		loading: false,
		qrActive: false,
		dialogCheck: false,
	}),
	computed: {
		...mapGetters(["isIOS"]),
		qrDialog: {
			get: function() {
				return this.$store.state.ui.qr.active;
			},
			set: function() {
				this.closedQRReader();
			},
		},
	},
	methods: {
		...mapMutations("ui", {
			changeQRData: "changeQRData",
			closedQRReader: "closedQRReader",
			openSnackbar: "openSnackbar",
		}),
		...mapActions("ui", { closedQRReader: "closedQRReader" }),
		acepptCheck() {
			this.$bus.emit("onDecode", {
				check: true,
			});
			this.dialogCheck = false;
			this.qrDialog = false;
		},
		onDecode(decodedString) {
			const part = decodedString.split("/");
			const idService = part[part.length - 1];

			// Guardar el token
			this.changeQRData(decodedString);

			this.$axios
				.get(`driver/transport-services/${idService}`)
				.then(() => {
					if (this.onlyEvent) {
						this.$bus.emit("onDecode", {
							id: part[part.length - 1],
						});
						this.qrDialog = false;
					} else {
						setImmediate(() => {
							this.closedQRReader();
							this.$router.push({
								name: "booking",
								params: { id: part[part.length - 1] },
								query: { checkin: true },
							});
						});
					}
				})
				.catch((error) => {
					setImmediate(() => {
						this.closedQRReader();
						if (error.response.status === 404) {
							this.openSnackbar(
								this.$t(
									"global.components.qrReader.txtServiceNoAsign"
								)
							);
						} else {
							this.openSnackbar(
								this.$t(
									"global.components.qrReader.txtErrorGeneric"
								)
							);
						}
					});
				});
		},
		async onInit(promise) {
			this.loading = true;
			try {
				await promise;
			} catch (error) {
				this.$emit("onError", error);
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		closed() {
			this.qrDialog = false;
			this.qrActive = false;
		},
	},
	mounted() {
		if (!this.select) {
			this.activeQr = true;
		}
	},
};
</script>

<style scoped>
.box .manual {
	float: left;
}

.box .item {
	width: 45%;
	background-color: #ede7f6;
	height: 100px;
}

.box .qr {
	float: right;
}
.box .clr {
	clear: both;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
